import {usePage} from '@inertiajs/inertia-react';

/**
 * @returns {App.User|undefined}
 */
export default () => {
  const {application: {user}} = usePage().props;

  if (user) {
    user.isAdmin = user.role.value === 'admin';
  }

  return {
    ...user,

    hasPermission: (...permissions) => {
      return user.isAdmin || permissions.some((permission) => user.permissions.includes(permission));
    },
    hasAllPermissions: (...permissions) => {
      return user.isAdmin || permissions.every(permission => user.hasPermission(permission));
    },
  };
}